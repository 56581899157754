// Variables
@import 'variables';

#slide-home {
    height: 65vh;

    #particles-js {
        width: 100%;
        height: 65%;
        //background-image: linear-gradient(45deg, #005C97, #363795);
        //background-image: linear-gradient(198deg,#47556a -1%,#010203 119%);
        background-color: #f4f4f4;
        background-repeat: no-repeat;
        background-size: cover;
        background-position: 50% 50%;
        position: absolute;
        z-index: -9999;
    }

    h1,
    h2,
    p {
        color: #000;
    }

    #slogan {
        letter-spacing: 0.6rem;
        font-size: 3rem;
        text-align: center;
        font-weight: 400;
    }

    #the-future {
        font-size: 3rem;
        font-weight: 400;
        color: $blue;
    }
}

#solutions {
    .row {
        margin-top: 3rem !important;

        img {
            width: 100% !important;
        }

        h3 {
            font-weight: 400 !important;
            margin-top: 1.5rem !important;
        }

        p {
            margin-bottom: 1rem !important;
        }
    }
}

#carousel-solution {
    margin-top: 3rem !important;

    .carousel {
        .row {
            margin: unset;
        }

        .col-6 {
            padding: unset;
            max-height: 55vh;

            h2 {
                font-size: 3rem !important;
                font-weight: 400 !important;
            }

            p {
                font-size: 2rem !important;
            }

            button {
                margin-top: 1.5rem !important;
            }
        }

        height: 100%;

        img {
            width: 100% !important;
        }

        .carousel__container {
            height: 100%;
            width: 100%;
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            padding: 8vw 5vw !important;
        }
    }
}

#product {
    margin-top: 3rem !important;

    .row {
        margin: unset;
    }

    .col-6,
    .col-12 {
        padding: unset;
    }

    .no-pr {
        padding-right: 0 !important;
    }

    img {
        -webkit-transform: scale(1);
        transform: scale(1);
        -webkit-transition: -webkit-transform cubic-bezier(0.4, 0, 0.2, 1) .3s;
        transition: -webkit-transform cubic-bezier(0.4, 0, 0.2, 1) .3s;
        transition: transform cubic-bezier(0.4, 0, 0.2, 1) .3s;
        transition: transform cubic-bezier(0.4, 0, 0.2, 1) .3s, -webkit-transform cubic-bezier(0.4, 0, 0.2, 1) .3s;
    }

    a {
        &:hover {
            img {
                -webkit-transform: scale(1.1);
                transform: scale(1.1);
            }

            text-decoration: none;
        }

        .col-12 {
            height: 100%;
        }
    }

    #one-products {

        .col-6,
        .col-12 {
            border-radius: 20px;
        }
    }

    #three-products {
        .col-12 {
            padding: unset;
            padding-left: 15px;
            border-radius: 20px;
        }

        .col-lg-6 {
            margin-bottom: 15px;
        }
    }
}

@media (max-width: $lg) {
    #slide-home {
        #slogan {
            font-size: 1.2rem;
        }

        #the-future {
            font-size: 1.5rem;
        }
    }

    #solutions {
        .solution-item {
            margin-bottom: 2rem;

            &:last-child {
                margin-bottom: 0 !important;
            }
        }
    }

    #carousel-solution {
        .carousel {
            .row {
                padding: 3rem 0;
            }

            .carousel__text {
                text-align: start;
                order: 2;
                margin-top: 1rem;

                h2 {
                    font-size: 1.5rem;
                }

                button {
                    margin-top: 1rem;
                }
            }

            .carousel__img {
                text-align: center;
                order: 1;
            }
        }
    }

    #product {

        .col-6,
        .col-12 {
            padding: 1rem 0;
        }

        #three-products {
            div:first-child {
                padding-top: 0;
            }

            .col-6,
            .col-12 {
                padding: 1rem 0;
                padding-left: 0;
            }

        }

    }
}